import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import Main from "./Containers/Main/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import axios from "axios";
import {
  setAppPropsAction,
  setConverseInfoAction,
  setPrebindError,
  setRefreshStartedAction,
  setTerminateError,
} from "./Containers/Main/actions";
import {
  MVA_FLOW,
  BOSH_URL,
  CONVERT_TOKEN_URL,
  PREBIND_URL,
  OBTAIN_TOKEN_URL,
  REFRESH_URL,
  USER_INFO_URL,
  WEB_LOGIN_REDIRECT,
  WEB_FLOW,
} from "./constants";
import { v4 as uuidv4 } from "uuid";

export const { store, history } = configureStore();

const handleWebError = () => {
  if (window.location !== window.parent.location) {
    window.parent.postMessage(
      "REFRESH TOKENS ID",
      process.env.REACT_APP_ECARE_URL!
    );
  } else {
    window.location.replace(WEB_LOGIN_REDIRECT);
  }
};

const handleMvaError = () => {
  window.ReactNativeWebView.postMessage("REFRESH TOKENS");
  window.ReactNativeWebView.postMessage("REFRESH TOKENS ID");
};

const handleError = (channel: string) => {
  if (channel === WEB_FLOW) {
    handleWebError();
  }
  if (channel === MVA_FLOW) {
    handleMvaError();
  }
};

(function (open) {
  let payload: any;
  // @ts-ignore
  XMLHttpRequest.prototype.realSend = XMLHttpRequest.prototype.send;
  // @ts-ignore
  payload = function (vData) {
    // @ts-ignore
    this.realSend(vData);
  };
  XMLHttpRequest.prototype.send = payload;
  // @ts-ignore
  XMLHttpRequest.prototype.open = function (method, url, async) {
    const state = store.getState();
    const channel = state.app.appFlow;
    const accessToken = state.app.accessToken;
    const refreshToken = state.app.refreshToken;
    const userName = state.app.userName;

    //we need to change the method from GET (how it is defined in conversejs) to POST (what we need for applicationLogin)
    if (url === PREBIND_URL) {
      method = "POST";
    }
    this.addEventListener(
      "readystatechange",
      function () {
        //Request interceptor
        if (this.readyState === 1) {
          //Add bearer to all requests, except the prebind request for the anonymous flow when we don't have an initial access token
          if (url === BOSH_URL) {
            this.setRequestHeader("Authorization", `Bearer ${accessToken}`);
            this.setRequestHeader("app-client-name", "tobi-web");
            this.setRequestHeader("vf-trace-transaction-id", uuidv4());
          }
          if (url === PREBIND_URL) {
            this.setRequestHeader("Authorization", `Bearer ${accessToken}`);
            // send flow as channel
            this.setRequestHeader("vfChannel", channel);
            if (userName) {
              // send username as nickname
              this.setRequestHeader("vfNickName", userName);
            }
            if (state.app.notificationBan && channel === MVA_FLOW) {
              // send the ban fot push notifications in case there is one
              this.setRequestHeader(
                "vfNotificationBan",
                state.app.notificationBan
              );
            }
          }
        }

        // Response interceptor
        if (this.readyState === 4) {
          // Success Interceptor
          if (this.status === 200) {
            // Update MVA tokens after PREBIND
            if (this.responseURL === PREBIND_URL) {
              const dataJson = JSON.parse(this.response);
              if (
                dataJson.jid &&
                dataJson.sid &&
                dataJson.rid &&
                dataJson.accessToken &&
                dataJson.refreshToken
              ) {
                store.dispatch(
                  setAppPropsAction(dataJson.accessToken, dataJson.refreshToken)
                );
                store.dispatch(
                  setConverseInfoAction(
                    dataJson.jid,
                    dataJson.sid,
                    dataJson.rid
                  )
                );
                store.dispatch(setPrebindError(false));
                return Promise.resolve(this.response);
              } else {
                store.dispatch(setPrebindError(true));
              }
            }

            //In case we receive "terminate" on a bosh call, we set error true in order to reinitialize the chat
            if (
              this.responseURL === BOSH_URL &&
              this.response.includes("type='terminate'")
            ) {
              store.dispatch(setTerminateError(true));
            }
          }
          // Error interceptor
          else if (this.status === 401) {
            // Refresh tokens if prebind
            // For bosh the logic in Converse is to make a new prebind call if 401
            if (
              this.responseURL === PREBIND_URL ||
              this.responseURL === USER_INFO_URL
            ) {
              store.dispatch(setRefreshStartedAction(true));
              return axios
                .post(REFRESH_URL, {
                  refreshToken: refreshToken,
                  accessToken: accessToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    store.dispatch(
                      setAppPropsAction(
                        res.data.accessToken,
                        res.data.refreshToken
                      )
                    );
                  }
                })
                .catch(() => {
                  //Error handled below
                });
            } else if (this.responseURL !== BOSH_URL) {
              handleError(channel);
              return;
            }
          } else {
            //Error handler for prebind
            if (this.responseURL === PREBIND_URL) {
              store.dispatch(setPrebindError(true));
            }
            if (
              this.responseURL === OBTAIN_TOKEN_URL ||
              this.responseURL === USER_INFO_URL ||
              this.responseURL === CONVERT_TOKEN_URL
            ) {
              handleError(channel);
              return;
            }
          }
        }
      },
      false
    );
    open.call(this, method, url, async);
  };
})(XMLHttpRequest.prototype.open);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Main />
    </Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
