import React, { useState } from "react";
import { IState } from "../../Containers/Main/model";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppPropsAction,
  setSelectedAccount,
  setSelectorOpened,
  setIsLoading,
  setErrorScreen,
} from "../../Containers/Main/actions";
import {
  LABELS_JSON,
  API,
  SUB_USER,
  POWER_USER,
  USER,
  FIXED_USER,
  UNAVAILABLE_ACCOUNT_MESSAGE,
  WEB_FLOW,
  SELECTED_IDENTITY_URL,
} from "../../constants";
// @ts-ignore
import SSOSelector from "@digital/sso-selector";
import "@digital/sso-selector/dist/index.css";
import axios from "axios";

const SsoSelector = ({ getUserInfo }: { getUserInfo: any }) => {
  const dispatch = useDispatch();
  const [pendingRequest, setPendingRequest] = useState(false);
  let { selectorOpened, accessToken, refreshToken, isIdentitySelected } =
    useSelector((state: IState) => state.app);

  const handleSubmit = async (changedBanId: any) => {
    if (pendingRequest === false) {
      await selectedIdentity(changedBanId);
    }
  };

  const handleCloseSelector = () => {
    if (isIdentitySelected) {
      dispatch(setSelectorOpened(false));
    }
  };

  const handleSelectorError = () => {
    dispatch(
      setErrorScreen({
        title: "Autentificare nereușită",
        content: UNAVAILABLE_ACCOUNT_MESSAGE,
        buttonText: "Încearcă din nou",
        onClick: () => {
          dispatch(setSelectorOpened(true));
        },
        backButtonShow: false,
        show: true,
      })
    );
    dispatch(setSelectorOpened(false));
  };

  const selectedIdentity = async (data: any) => {
    setPendingRequest(true);
    const url = SELECTED_IDENTITY_URL;
    const headers = {
      withCredentials: true,
      Authorization: `Bearer ${accessToken}`,
    };
    const payloadData = data[data.length - 1];
    const res = await axios
      .post(url, { ...payloadData }, headers)
      .then((response) => {
        setPendingRequest(false);
        dispatch(
          setErrorScreen({
            title: "Autentificare nereușită",
            content: UNAVAILABLE_ACCOUNT_MESSAGE,
            buttonText: "Încearcă din nou",
            onClick: () => {
              dispatch(setSelectorOpened(true));
            },
            backButtonShow: false,
            show: false,
          })
        );
        if (
          response.data.payload.response.upcLegacyRole &&
          response.data.payload.response.upcLegacyRole.vfCRMRole === FIXED_USER
        ) {
          dispatch(
            setSelectedAccount(
              `${response.data.payload.response.upcLegacyRole.upcBanBaseId}-${response.data.payload.response.upcLegacyRole.upcBanExtensionId}`,
              response.data.payload.response.vfEntityID
            )
          );
        } else if (
          (response.data.payload.response.legacyRole &&
            response.data.payload.response.legacyRole.vfCRMRole === SUB_USER) ||
          response.data.payload.response.legacyRole.vfCRMRole === POWER_USER ||
          (response.data.payload.response.legacyRole.vfCRMRole === USER &&
            (response.data.payload.response.legacyRole.vfIsPrepaid === "true" ||
              response.data.payload.response.legacyRole.vfIsHybrid === "true"))
        ) {
          dispatch(
            setSelectedAccount(
              response.data.payload.response.legacyRole.vfPhoneNumber,
              response.data.payload.response.vfEntityID
            )
          );
        } else {
          dispatch(
            setSelectedAccount(
              response.data.payload.response.legacyRole.vfOdsBan,
              response.data.payload.response.vfEntityID
            )
          );
        }

        dispatch(
          setAppPropsAction(
            response.data.payload.tokens.accessToken,
            response.data.payload.tokens.refreshToken
          )
        );
        dispatch(setIsLoading(true));
        getUserInfo(response.data.payload.tokens.accessToken, WEB_FLOW);
      })
      .catch((err: any) => {
        setPendingRequest(false);
        if ([500].includes(err?.response?.status)) {
          dispatch(
            setErrorScreen({
              title: "Autentificare nereușită",
              content: UNAVAILABLE_ACCOUNT_MESSAGE,
              buttonText: "Încearcă din nou",
              onClick: () => {
                dispatch(setSelectorOpened(true));
              },
              backButtonShow: false,
              show: true,
            })
          );
          dispatch(setSelectorOpened(false));
        }
      });
    return { res };
  };

  return (
    <SSOSelector
      isWebPage={false}
      labelsJson={LABELS_JSON}
      isOpen={selectorOpened}
      hasOverlay={false}
      requestsObj={API}
      isFirstOpen={false}
      isOtp={false}
      onClose={handleCloseSelector}
      onSubmit={handleSubmit}
      onError={handleSelectorError}
      authorization={{
        accessToken: accessToken || null,
        refreshToken: refreshToken || null,
      }}
    />
  );
};

export default SsoSelector;
