import {IConverseState} from "./model";
import {
  CALL_PREBIND,
  CALL_PREBIND_FAILURE,
  CALL_PREBIND_SUCCESS,
} from "./constants";

const converseInitialState: IConverseState = {
  loading: false,
  jid: '',
  sid: '',
  rid: ''
};

export const converseReducer = (state = converseInitialState, action: any) => {
  switch (action.type) {
    case CALL_PREBIND:
      return {
        ...state,
        loading: true
      };
    case CALL_PREBIND_SUCCESS:
      return {
        ...state,
        jid: action.jid,
        sid: action.sid,
        rid: action.rid,
        loading: false
      };
    case CALL_PREBIND_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
