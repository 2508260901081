import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import {appReducer} from "../Containers/Main/reducer";
import {converseReducer} from "../Components/Converse/reducer";

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    converse: converseReducer
  });

export default rootReducer;
