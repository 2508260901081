import axios, {AxiosRequestConfig} from 'axios'

let axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 60000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
  }
}

let axiosInstance = axios.create(axiosConfig);

export default axiosInstance
