import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {
  MAINTENANCE_MESSAGE_BODY,
  MAINTENANCE_MESSAGE_TITLE,
  MAINTENANCE_MESSAGE_LINK,
  MAINTENANCE_MESSAGE_BODY_WHATSAPP,
  WEB_LOGOUT_REDIRECT,
} from "../../constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setAppPropsAction } from "../../Containers/Main/actions";
import { IState } from "../../Containers/Main/model";

const MaintenanceBanner = () => {
  const dispatch = useDispatch();
  let { accessToken, refreshToken } = useSelector((state: IState) => state.app);
  let dataLayer = window.dataLayer || [];
  const [loggedIn, setLoggedIn] = useState(false);
  const url = window.location.search;
  let params = new URLSearchParams(url);
  let flow: string | null = null;
  if (url) {
    flow = params.get("flow");
  }

  useEffect(() => {
    if (accessToken && refreshToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [accessToken, refreshToken]);

  const logoutClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Deconectare", // required name of the clicked element
      page_name: "Tobi ", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const handleLogout = () => {
    logoutClicked();
    axios
      .post(
        WEB_LOGOUT_REDIRECT,
        {
          accessToken: accessToken,
          refreshToken: refreshToken,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setLoggedIn(false);
        dispatch(setAppPropsAction(null, null));
        window.location.replace(response.data.afterLogoutUrl);
      })
      .catch((error) => {
        //TO DO: Add warning message if needed
      });
  };

  return (
    <Container fluid="md" className="text-center mt-2">
      <Row>
        {!flow && loggedIn && (
          <Fragment>
            <Row className="pe-1">
              <Col className="text-end px-3">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => handleLogout()}
                >
                  Deconectare
                </Button>
              </Col>
            </Row>
            <br />
          </Fragment>
        )}
        <Col className="d-flex flex-column align-items-center py-5 mt-3">
          <img
            className="tobi-maintenance-avatar mb-3"
            alt="TOBi Mentenanta"
            src="https://static.vodafone.ro/tobi/images/Tobi-1mai.png"
          />
          <h3 className="font-weight-bold tobi-maintenance-body">
            {MAINTENANCE_MESSAGE_TITLE}
          </h3>
          <h5 className="mt-4 mb-3 text-center tobi-maintenance-title">
            {MAINTENANCE_MESSAGE_BODY}
            <br />
            {MAINTENANCE_MESSAGE_BODY_WHATSAPP}
            <a href={MAINTENANCE_MESSAGE_LINK}>{MAINTENANCE_MESSAGE_LINK}</a>
          </h5>
        </Col>
      </Row>
    </Container>
  );
};

export default MaintenanceBanner;
