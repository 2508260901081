import { PREBIND_URL } from '../constants';
import axiosInstance from './AxiosInstance'

export const TokenEndpoints = {
  getAnonymousToken: PREBIND_URL,
}

class TokenService {
  getAnonymousToken () {
    const url = TokenEndpoints.getAnonymousToken
    return axiosInstance
      // @ts-ignore
      .post(url, null)
      .then((resp: { data: { payload: any; }; }) => {
        if(resp) {
          if (resp.data && resp.data.payload) {
            return Promise.resolve(resp.data.payload)
          }
          return Promise.reject(resp.data)
        }
        return Promise.reject(resp)
      })
      .catch((e: any) => Promise.reject(e))
  }
}

const tokenService = new TokenService()
export default tokenService
