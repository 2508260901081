import React, { Fragment, useEffect } from "react";
import Converse from "../../Components/Converse/Converse";
import Error from "../../Components/Error/Error";
import { IState } from "../Main/model";
import {
  WEB_FLOW,
  TOBI_OFF_MESSAGE,
  MVA_FLOW,
  UNAVAILABLE_CHAT_MESSAGE,
} from "../../constants";
import Loading from "../../Components/Loading/Loading";
import SsoSelector from "../../Components/SsoSelector/SsoSelector";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPrebindError, setErrorScreen } from "../Main/actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { setShowGdprModal } from "../../Containers/Main/actions";

const Chat = ({ getUserInfo }: { getUserInfo: any }) => {
  let {
    appFlow,
    isTobiOn,
    prebindError,
    accessToken,
    isIdentitySelected,
    selectorOpened,
    errorScreen,
    hasUserInfoFinished,
    showGdprModal,
    entityId,
  } = useSelector((state: IState) => state.app);
  const dispatch = useDispatch();
  const history = useHistory();
  let dataLayer = window.dataLayer || [];
  const url = window.location.search;
  let params = new URLSearchParams(url);
  let flow: string | null = null;
  if (url) {
    flow = params.get("flow");
  }

  useEffect(() => {
    onPageLoaded();
  }, []);

  useEffect(() => {
    if (!isTobiOn && isTobiOn !== null) {
      dispatch(
        setErrorScreen({
          title: "Salut,",
          content: TOBI_OFF_MESSAGE,
          buttonText: "",
          onClick: () => {
            dispatch(setPrebindError(false));
            history.push("/");
          },
          backButtonShow: true,
          show: false,
        })
      );
    }
  }, [isTobiOn]);

  useEffect(() => {
    if (prebindError) {
      dispatch(
        setErrorScreen({
          title: "A apărut o eroare",
          content: UNAVAILABLE_CHAT_MESSAGE,
          buttonText: "Încearcă din nou",
          onClick: () => {
            dispatch(setPrebindError(false));
          },
          backButtonShow: true,
          show: false,
        })
      );
    }
  }, [prebindError]);

  const onPageLoaded = () => {
    let dataObject = {
      event: "microData", // required for every push
      event_name: "page_view", // required for every push on every change of the content page (ex. dropdown from listing ) or url change
      page_name: "Tobi Chat", // required name of the page
      page_type: "chat page", //could be homepage, product page, listing page, search page, etc
      page_platform: appFlow === WEB_FLOW ? "website" : "mva",
      page_charset: "UTF-8",
      page_country: "RO",
      page_domain: "tobi.vodafone.ro",
      page_locale: "RO-ro",
      page_section: "Tobi",
      page_channel: "vodafone.ro",
      user_selected_journey: "NO_FLOW", //possible values: ''NO_FLOW' (default), 'RET', 'ACQ', 'MNP', 'MFP', 'Servicii fixe', 'cartela', 'dongle', 'SERVICE_ONLY'
      client_upc: "no", // possible values "yes" or "no", "no" default
    };
    dataLayer.push(dataObject);
  };

  const handleBackToHomeButton = () => {
    backButtonClicked();
    window.location.replace(process.env.REACT_APP_TOBI_URL!);
  };

  const backButtonClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Inapoi la pagina principala", // required name of the clicked element
      page_name: "Tobi Chat", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const gdprLinkClicked = () => {
    let clickDataObject = {
      event: "microData", // required
      event_name: "action_click", // required
      click_element_name: "Nota de informare", // required name of the clicked element
      page_name: "Tobi Chat", // required name of the page where click happened
    };
    dataLayer.push(clickDataObject);
  };

  const handleClose = () => {
    let gdprModalShowed = isJson(localStorage.getItem("modalShowed")) || {};
    if (entityId) {
      gdprModalShowed[entityId] = `${new Date(new Date().toDateString())}`;
      localStorage.setItem(`modalShowed`, JSON.stringify(gdprModalShowed));
    }
    dispatch(setShowGdprModal(false));
  };

  const isJson = (str: string | null) => {
    if (str) {
      let parsedString;
      try {
        parsedString = JSON.parse(str);
      } catch (e) {
        // handle error if needed
      }
      return parsedString;
    }
    return false;
  };

  const renderWhenIdentitySelected = () => {
    if (
      (((!isTobiOn && isTobiOn !== null) || errorScreen.show) &&
        (appFlow === WEB_FLOW ||
          !(flow && flow === "withAuth" && window.ReactNativeWebView))) ||
      prebindError
    ) {
      return (
        <Fragment>
          <Error
            title={errorScreen.title}
            content={errorScreen.content}
            buttonText={errorScreen.buttonText}
            backButtonShow={errorScreen.backButtonShow}
            onClick={errorScreen.onClick}
          />
          {selectorOpened &&
            (appFlow === WEB_FLOW ||
              !(flow && flow === "withAuth" && window.ReactNativeWebView)) && (
              <SsoSelector getUserInfo={getUserInfo} />
            )}
        </Fragment>
      );
    } else {
      return <Fragment>{renderWhenNoError()}</Fragment>;
    }
  };

  const renderWhenNoError = () => {
    if (
      (accessToken && isIdentitySelected && appFlow === WEB_FLOW && isTobiOn) ||
      (accessToken &&
        hasUserInfoFinished &&
        (appFlow === MVA_FLOW ||
          (flow && flow === "withAuth" && window.ReactNativeWebView)))
    ) {
      return (
        <Fragment>
          {appFlow === WEB_FLOW && window.location === window.parent.location && (
            <div className="back-button-container">
                <Button
                  type="button"
                  className="ms-3 mt-2 back-button"
                  variant="primary"
                  id="back-button"
                  onClick={handleBackToHomeButton}
                >
                  Înapoi la pagina principală
                </Button>
            </div>
          )}
          <Converse />
          <Modal show={showGdprModal} onHide={handleClose}>
            <Modal.Header closeButton className="modal-header-gdpr">
              <Modal.Body>
                Aceasta conversatie poate include prelucrare de date cu caracter
                personal. Pentru detalii acceseaza:{" "}
                <a
                  href="https://www.vodafone.ro/termeni-proceduri-legale/tobi-privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => gdprLinkClicked()}
                >
                  https://www.vodafone.ro/termeni-proceduri-legale/tobi-privacy
                </a>
                .
              </Modal.Body>
            </Modal.Header>
          </Modal>
        </Fragment>
      );
    } else {
      return <Loading page="Chat" />;
    }
  };

  return (
    <Fragment>
      {selectorOpened &&
      (appFlow === WEB_FLOW ||
        !(flow && flow === "withAuth" && window.ReactNativeWebView)) ? (
        <SsoSelector getUserInfo={getUserInfo} />
      ) : (
        <Fragment>{renderWhenIdentitySelected()}</Fragment>
      )}
    </Fragment>
  );
};

export default Chat;
