import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";

const Loading = ({ page }: { page: string }) => {
  // @ts-ignore
  return (
    <Fragment>
      {page === "Chat" ? (
        <Container fluid="md" className="spinner-custom-container">
          <Container className="spinner-custom-group">
            <img
              src="https://static.vodafone.ro/tobi/images/tobi-icon.webp"
              className="spinner spinner-custom"
              alt="Tobi"
            />
            <p className="spinner-text">Se încarcă...</p>
          </Container>
        </Container>
      ) : (
        <Container fluid="md" className="h-100 d-flex">
          <span className="spinner-new">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              className="svg-inline--fa fa-spinner fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
          </span>
        </Container>
      )}
    </Fragment>
  );
};

export default Loading;
