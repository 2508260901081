import { IAppsState } from "./model";
import {
  SET_APP_FLOW,
  SET_NICKNAME,
  SET_APP_PROPS,
  SET_REFRESH_STARTED,
  SET_CONVERSE_INFO,
  SET_PREBIND_ERROR,
  SET_TERMINATE_ERROR,
  GET_ANONYMOUS_TOKEN_REQUEST,
  GET_ANONYMOUS_TOKEN_ERROR,
  SET_USER_NAME,
  SET_SELECTED_ACCOUNT,
  SET_SELECTOR_OPENED,
  SET_IS_TOBI_ON,
  SET_IS_LOADING,
  SET_IS_IDENTITY_SELECTED,
  SET_ERROR_SCREEN,
  SET_HAS_USER_INFO_FINISHED,
  SET_NOTIFICATION_BAN,
  SET_SHOW_GDPR_MODAL,
} from "./constants";

const appInitialState: IAppsState = {
  loading: false,
  error: '',
  accessToken: '',
  refreshToken: '',
  isTokenRefreshStarted: false,
  jid: '',
  sid: '',
  rid: '',
  appFlow: '',
  nickname: '',
  userName: '',
  prebindError: false,
  terminateError: false,
  selectedAccount: '',
  selectorOpened: null,
  isTobiOn: null,
  isLoading: true,
  isIdentitySelected: false,
  errorScreen: {
    title: '',
    content: '',
    buttonText: '',
    onClick: () => {
      // This is intentional
    },
    backButtonShow: true,
    show: false,
  },
  hasUserInfoFinished: false,
  notificationBan: null,
  showGdprModal: false,
  entityId: null,
};

export const appReducer = (state = appInitialState, action: any) => {
  switch (action.type) {
    case SET_APP_FLOW:
      return {
        ...state,
        appFlow: action.appFlow,
      };
    case SET_NICKNAME:
      return {
        ...state,
        nickname: action.nickname,
      };
    case SET_USER_NAME:
      return {
        ...state,
        userName: action.userName,
      };
    case SET_APP_PROPS:
      return {
        ...state,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
        loading: false
      };
    case SET_REFRESH_STARTED:
      return {
        ...state,
        isTokenRefreshStarted: action.isTokenRefreshStarted
      };
    case SET_CONVERSE_INFO:
      return {
        ...state,
        jid: action.jid,
        sid: action.sid,
        rid: action.rid,
      };
    case GET_ANONYMOUS_TOKEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_ANONYMOUS_TOKEN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_PREBIND_ERROR:
      return {
        ...state,
        prebindError: action.prebindError,
    };
    case SET_TERMINATE_ERROR:
      return {
        ...state,
        terminateError: action.terminateError,
    };
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.selectedAccount,
        entityId: action.entityId,
    };
    case SET_SELECTOR_OPENED:
      return {
        ...state,
        selectorOpened: action.selectorOpened,
    };
    case SET_IS_TOBI_ON:
      return {
        ...state,
        isTobiOn: action.isTobiOn,
    };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
    };
    case SET_IS_IDENTITY_SELECTED:
      return {
        ...state,
        isIdentitySelected: action.isIdentitySelected,
    };
    case SET_ERROR_SCREEN:
      return {
        ...state,
        errorScreen: action.errorScreen,
    };
    case SET_HAS_USER_INFO_FINISHED:
      return {
        ...state,
        hasUserInfoFinished: action.hasUserInfoFinished,
    };
    case SET_NOTIFICATION_BAN:
      return {
        ...state,
        notificationBan: action.notificationBan,
    };
    case SET_SHOW_GDPR_MODAL:
      return {
        ...state,
        showGdprModal: action.showGdprModal,
    };
    default:
      return state;
  }
};
