import { default as React, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { callPrebindAction } from "./actions";

declare global {
  interface Window {
    converse: any;
    ReactNativeWebView: any;
    originalPostMessage: any;
  }
  namespace JSX {
    interface IntrinsicElements {
      ['converse-root']: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const Converse: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(callPrebindAction());
  }, []);

  return (
    <div id="converse-container">
      <converse-root></converse-root>
    </div>
  );
};

export default Converse;
