import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { WEB_FLOW } from "../../constants";
import { IState } from "../../Containers/Main/model";

const Error = ({
  title,
  content,
  buttonText,
  onClick,
  backButtonShow,
}: {
  title: string;
  content: string;
  buttonText: string;
  onClick: any;
  backButtonShow: boolean;
}) => {
  const history = useHistory();
  let { appFlow } = useSelector((state: IState) => state.app);
  const path = window.location.pathname;

  return (
    <Container fluid="md">
      <Row>
        <Col className="d-flex flex-column align-items-center py-5">
          <h3>{title}</h3>
          <h5 className="mt-4 mb-3 text-center">{content}</h5>
          {buttonText && (
            <Button type="button" variant="primary" onClick={onClick}>
              {buttonText}
            </Button>
          )}
          {appFlow === WEB_FLOW &&
            path.includes("/chat") &&
            backButtonShow &&
            window.location === window.parent.location && (
              <Button
                className="mt-3"
                type="button"
                variant="primary"
                onClick={() => history.push("/")}
              >
                Înapoi la pagina principală
              </Button>
            )}
        </Col>
      </Row>
    </Container>
  );
};

export default Error;
