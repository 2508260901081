import {
  CALL_PREBIND,
  CALL_PREBIND_FAILURE,
  CALL_PREBIND_SUCCESS,
} from "./constants";

export const callPrebindAction = () => ({
  type: CALL_PREBIND
});

export const callPrebindSuccessAction = (jid: string, sid: string, rid: string) => ({
  type: CALL_PREBIND_SUCCESS,
  jid,
  sid,
  rid
});
export const callPrebindFailureAction = (error: any) => ({
  type: CALL_PREBIND_FAILURE,
  error
});
