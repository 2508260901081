export const BASE_API_URL = "https://e9358359.ngrok.io";
export const MVA_FLOW = "mva";
export const WEB_FLOW = "web";
export const INTERNAL_FLOW = "internal";
export const URL_STORE_HUAWEI = "https://bit.ly/AppUpdate-Huawei";
export const URL_STORE_ANDROID = "https://bit.ly/AppUpdate-Android";
export const URL_STORE_APPLE = "https://apple.co/37ndrqF";
export const URL_STORE_DEFAULT = "https://www.vodafone.ro/aplicatia-myvodafone/";
export const LATEST_VERSION="6.9.3"
export const OBTAIN_TOKEN_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/obtaintoken/tobi-web"
export const CONVERT_TOKEN_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/convert-token/tobi-web"
export const USER_INFO_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/userinfo/tobi-web?skipCache=false"
export const SELECTED_IDENTITY_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/api/identity/selectedidentity/tobi-web"
export const PREBIND_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/applicationLogin/tobi-web"
export const BOSH_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-tobi-bff/api/bosh"
export const REFRESH_URL= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/refresh/tobi-web"
export const WEB_LOGIN_REDIRECT= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/login?client=tobi-web"
export const WEB_LOGOUT_REDIRECT= process.env.REACT_APP_BASE_API_URL + "/dxl-aws-sso/logout/tobi-web"
export const CONTACT_ROLES_URL="/dxl-identity-selector/contact-roles?skipCache=false"
export const SUBSCRIBERS_URL="/dxl-identity-selector/bans/subscribers?skipCache=false"
export const DISASSOCIATE_URL="/dxl-identity-selector/disassociated-roles/tobi-web"
export const LABELS_JSON = {
  sso: {
    header: '',
    subheader: 'Alege contul pentru care vrei să îi soliciți ajutorul lui TOBi.',
    submitBtn: '',
    selectLabels: {
      holding: '',
      account: '',
      ban: '',
      subscriber: '',
    },
  },
  ssoDisassociate: {
    header: '',
    subheader: '',
    cancelBtn: '',
    submitBtn: '',
  },
  ssoOTP: {
    header: '',
    subheader: '',
    submitBtn: '',
  },
  ssoNonVdf: 'eroare',
};
export const API = {
  refreshToken: process.env.REACT_APP_BASE_API_URL! + REFRESH_URL,
  getContactRoles: process.env.REACT_APP_BASE_API_URL! + CONTACT_ROLES_URL,
  getSubscribers: process.env.REACT_APP_BASE_API_URL! + SUBSCRIBERS_URL,
  disassociate: process.env.REACT_APP_BASE_API_URL! + DISASSOCIATE_URL
};
export const POWER_USER = 'PowerUser'
export const USER = 'User'
export const SUB_USER = 'SubUser'
export const FIXED_USER = 'FixedContractOwner'
export const PREPAID = 'prepaid'
export const POSTPAID = 'postpaid'
export const HYBRID = 'hybrid'
export const EBU = 'EBU'
export const NONVODAFONE = 'nonvodafone'
export const TOBI_CONFIG = {
  "prepaid": {
    "isTobiOn": true
  },
  "postpaid": {
    "isTobiOn": true
  },
  "nonvodafone": {
    "isTobiOn": false
  },
  "hybrid": {
    "isTobiOn": false
  },
  "ebu": {
    "isTobiOn": true
  },
  "vodFlex": {
    "isTobiOn": true
  },
  "upc": {
    "isTobiOn": true
  }
}
export const ALLOWED_USERS = ['matei.misarca@gmail.com', 'andreea.pantu@gmail.com', 'dorin.popescu@vodafone.com', 'paraschiv.stefan@vodafone.com', 'cicerone.herescu@vodafone.com','o3@mailinator.com']
export const TOBI_OFF_MESSAGE = 'Momentan învăț pentru a putea rezolva și solicitările celor care nu au număr Vodafone, iar din acest motiv accesul tău către chat e restricționat. Poți oricând vorbi cu mine dacă te loghezi cu un cont persoană fizică (abonament, cartelă, servicii fixe) sau un cont persoană juridică.'
export const UNAVAILABLE_CHAT_MESSAGE = 'Chat-ul nu poate fi accesat momentan. Ne pare rău, încercăm să remediem această situație chiar acum.'
export const UNAVAILABLE_ACCOUNT_MESSAGE = 'Contul nu poate fi accesat momentan. Ne pare rău, încercăm să remediem această situație chiar acum.'
export const MAINTENANCE_MESSAGE_TITLE = 'Bine de știut'
export const MAINTENANCE_MESSAGE_BODY = 'Vrem să îți oferim cea mai bună experiență atunci când ne vizitezi. Ne îmbunătățim sistemele, motiv pentru care anumite funcționalități în aplicație sunt indisponibile momentan.'
export const MAINTENANCE_MESSAGE_BODY_WHATSAPP = 'Între timp poți lua legătura cu TOBi prin Whatsapp aici: '
export const MAINTENANCE_MESSAGE_LINK = 'https://bit.ly/Tobi-WhatsApp'
export const MAINTENANCE_BANNER = false