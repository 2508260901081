import { put, takeLatest, call } from "redux-saga/effects";
import { callPrebindFailureAction } from "./actions";
import { CALL_PREBIND } from "./constants";
import { store } from "../../index";
import {
  setRefreshStartedAction,
  setTerminateError,
} from "../../Containers/Main/actions";
import {
  BOSH_URL,
  PREBIND_URL,
  WEB_FLOW,
} from "../../constants";

let isDisconnected = false; // used to identify a 401 in wait()

const converseInitialize = (nickname: string) => {
  window.converse.initialize({
    bosh_service_url: BOSH_URL,
    prebind_url: PREBIND_URL,
    prebind: true,
    debug: false,
    authentication: "prebind",
    auto_login: true,
    auto_reconnect: true,
    auto_focus: false,
    auto_join_on_invite: true,
    auto_register_muc_nickname: true,
    muc_fetch_members: false,
    nickname: nickname,
    view_mode: "embedded",
    discover_connection_methods: false,
    keepalive: false,
    allow_logout: false,
    allow_registration: false,
    play_sounds: false,
    roster_groups: false,
    xhr_user_search: false,
    allow_bookmarks: false,
    allow_contact_removal: false,
    allow_contact_requests: false,
    allow_muc_invitations: true,
    auto_list_rooms: true,
    allow_non_roster_messaging: true,
    visible_toolbar_buttons: {
      emoji: false,
    },
    i18n: "ro",
    show_controlbox_by_default: false,
    allow_message_corrections: false,
    allow_message_retraction: false,
    show_only_online_users: true,
    whitelisted_plugins: ["reinitializeConversePlugin"],
    blacklisted_plugins: ["converse-notification", "converse-controlbox"],
    show_desktop_notifications: false,
    show_chat_state_notifications: false,
    dark_theme: "classic",
    theme: "classic",
    show_message_avatar: false,
    persistent_store: "sessionStorage",
  });
};

const reinitializeConversePlugin = () => {
  window.converse.plugins.add("reinitializeConversePlugin", {
    initialize: function () {
      const _converse = this._converse;
      let initialAccToken = store.getState().app.accessToken;

      _converse.api.listen.on("connected", function () {
        const converseContainer = document.querySelector("#converse-container");
        const converseEl = document.querySelector("#conversejs");
        if (converseEl) {
          converseContainer?.appendChild(converseEl);
        }
        initialAccToken = store.getState().app.accessToken;
        //check if mobile or webview and if so, resize the height of the body to avoid scroll under the input message
        let mobileDevices =
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        if (mobileDevices.test(navigator.userAgent)) {
          setPlatformInfo();
          waitForElementToDisplay("#conversejs .chat-textarea", 1000, true);
          document.body.classList.add("mobile");
        } else {
          waitForElementToDisplay("#conversejs .chat-textarea", 1000, false);
          setBrowserInfo();
        }
        if (store.getState().app.appFlow === WEB_FLOW && window.location === window.parent.location) {
          document.body.classList.add("web");
        }
      });

      // Used to reinitialize converse after a 401
      // A check was needed to avoid an infinite loop and to be sure that is triggered only on a refresh scenario
      function wait() {
        let isTokenRefreshStarted = store.getState().app.isTokenRefreshStarted;
        const containerEl = document.querySelector("#conversejs");
        if (isTokenRefreshStarted || isDisconnected) {
          const currentAccToken = store.getState().app.accessToken;
          if (initialAccToken !== currentAccToken) {
            store.dispatch(setRefreshStartedAction(false));
            isDisconnected = false;
            containerEl?.remove()
            const { nickname } = store.getState().app;
            converseInitialize(nickname);
          } else if (store.getState().app.terminateError) {
            store.dispatch(setTerminateError(false));
            isDisconnected = false;
            containerEl?.remove();
            const { nickname } = store.getState().app;
            converseInitialize(nickname);
          } else {
            setTimeout(wait, 500);
          }
        } else {
          return;
        }
      }

      function waitToJoin(_converse: any) {
        if (
          _converse?.rooms_list_view?.list_model?.id
        ) {
          setTimeout(() => {
            const domain = _converse.domain;
            const bare_jid_id = _converse.bare_jid.match(
              new RegExp("(.*)@" + domain)
            );
            const roomName = `room_${bare_jid_id[1]}@conference.${domain}`;
            _converse.api.rooms.open(
              roomName,
              {
                auto_configure: true,
              },
              true
            );
          }, 1000);
        } else {
          setTimeout(wait, 500);
        }
      }

      _converse.api.listen.on("noResumeableBOSHSession", function () {
        wait();
      });

      _converse.api.listen.on("disconnected", function () {
        _converse.api.user.logout();
        isDisconnected = true;
        wait();
      });

      _converse.api.listen.on("chatBoxesFetched", function () {
        waitToJoin(_converse);
      });
    },
  });
};

function waitForElementToDisplay(selector: any, time: any, isMobile: boolean) {
  if (document.querySelector(selector) != null) {
    if (isMobile) {
      let textareaBox = document.querySelector(selector);
      const chatContent = document.querySelector("converse-chat-content");
      const firstInnerHeight = window.innerHeight;
      textareaBox.addEventListener("focus", function (e: any) {
        e.preventDefault();
        document.body.classList.add("keyboard");
        setTimeout(function () {
          if (firstInnerHeight === window.innerHeight) {
            window.scrollTo(0, window.innerHeight - 200);
          }
          if (chatContent) {
            if (
              chatContent.scrollHeight - chatContent.scrollTop ===
              chatContent.clientHeight
            ) {
              chatContent.scrollTo(0, chatContent.scrollHeight);
            } else {
              chatContent.scrollTo(0, chatContent.scrollTop);
            }
            showHideScrollButton();
          }
        }, 200);
      });

      textareaBox.addEventListener("blur", function (e: any) {
        e.preventDefault();
        document.body.classList.remove("keyboard");
        showHideScrollButton();
      });
      chatContent?.addEventListener("scroll", () =>
        showHideScrollButton()
      );
      const fullWindowHeight = window.innerHeight;
      window.visualViewport.onresize = function (event: any) {
        event.preventDefault();
        if (
          document.body.classList.contains("keyboard") &&
          window.innerHeight === fullWindowHeight &&
          (window.ReactNativeWebView ||
            document.body.classList.contains("safari"))
        ) {
          textareaBox.blur();
        }
      };
    } else {
      checkCarouselContainer();
      // Options for the observer (which mutations to observe)
      const config = { childList: true, subtree: true };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            checkCarouselContainer();
          }
        }
      });

      // Start observing the target node for configured mutations
      observer.observe(document.body, config);
    }
  } else {
    setTimeout(function () {
      waitForElementToDisplay(selector, time, isMobile);
    }, time);
  }
}

function setPlatformInfo() {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("appleweb") !== -1) {
    if (ua.indexOf("chrome") > -1) {
      document.body.classList.add("chrome");
    } else if (ua.indexOf("version") !== -1) {
      document.body.classList.add("safari");
    } else {
      document.body.classList.add("chrome");
    }
    const chatContent = document.querySelector("converse-chat-content");
    if (chatContent) {
      chatContent.scrollTop = chatContent.scrollHeight;
    }
  }
}

function setBrowserInfo() {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("mac os") === -1) {
    document.body.classList.add("windows");
  } else {
    document.body.classList.add("macos");
  }
}

function showHideScrollButton() {
  const scrollBtn = document.querySelector(".scroll-bottom-btn");
  const chatContent = document.querySelector("converse-chat-content");
  if (scrollBtn && chatContent) {
    if (chatContent.scrollTop > -170) {
      scrollBtn.classList.remove("show-btn");
      scrollBtn.classList.add("hide-btn");
    } else if (chatContent.scrollTop <= -170) {
      scrollBtn.classList.remove("hide-btn");
      scrollBtn.classList.add("show-btn");
    }
  }
}

function checkCarouselContainer() {
  const containers = [".oob-extended-carousel-container", ".price-plan-container"];
  containers.forEach(container => {
    document.querySelectorAll(container).forEach(container => {
      window.addEventListener('resize', () => checkScrollable(container))
      checkScrollable(container);
    })
  })
}

function checkScrollable(container: any) {
  const element = container.children[0];
  if (element.scrollWidth > element.offsetWidth) {
    element.querySelectorAll(".scroll-arrow").forEach((arrow: any) => arrow.classList.remove("arrow-invisible"));
  } else {
    element.querySelectorAll(".scroll-arrow").forEach((arrow: any) => arrow.classList.add("arrow-invisible"));
  }
}

export function* callPrebindSaga() {
  try {
    const { nickname } = store.getState().app;
    yield call(converseInitialize, nickname);
    yield call(reinitializeConversePlugin);
  } catch (error) {
    yield put(callPrebindFailureAction(error));
  }
}

export default [takeLatest(CALL_PREBIND, callPrebindSaga)];
